ul {
	margin: 0;
	padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

#root {
	overflow-x: hidden;
}

@media (min-width: 992px) {
	.container{
		max-width: 900px;
	}
}

/* xl */
@media (min-width: 1200px) {
	.container{
		max-width: 900px;
	}
}

h1 {
	font-size: 3em;
}

h4 {
	font-size: 1.1em;
	font-weight: bolder;
}

strong {
	color: black;
}

.even {
	background-color: #fafafa;
}

.logo {
	padding-top: 8px;
	width: 140px;
}

.category {
	text-align: center;
	background-color: #f0f0f0;
	font-size: 18px;
	padding: 8px 2px;
}

.font-small {
	font-size: 14px;
	color: #303030;
}

.series {
	font-size: 36px;
	padding-left: 20px;
	padding-bottom: 10px;
}

.course_date {
	font-weight: bold;
    font-size: 0.9em;
}

@media (min-width: 768px) {
	.series {
		line-height: 120px;
		vertical-align: bottom;
		padding-bottom: 0;
		padding-left: 0px;
	}

	.course_date {
		font-weight: bold;
		font-size: 0.95em;
	}

}

.navbar-brand
{
	white-space: normal;
}


@media (min-width: 992px) {
	.series {
		line-height: 140px;
		vertical-align: bottom;
		padding-bottom: 0;
		padding-left: 20px;
		font-size: 2.5em;
	}
}

